const i18n = {
  'en-US': {
    'login.form.title': 'Login to 梦的天空之城',
    'login.form.phone.errMsg': 'phone number cannot be empty',
    'login.form.code.errMsg': 'phone number code cannot be empty',
    'login.form.phone.placeholder': 'your phone number',
    'login.form.code.placeholder': 'phone message code',
    'login.form.next': 'next',
    'login.form.login': 'login',
    'login.banner.slogan1': 'Out-of-the-box high-quality template',
    'login.banner.subSlogan1': 'Rich page templates, covering most typical business scenarios',
    'login.banner.slogan2': 'Built-in solutions to common problems',
    'login.banner.subSlogan2': 'Internationalization, routing configuration, state management everything',
    'login.banner.slogan3': 'Access visualization enhancement tool AUX',
    'login.banner.subSlogan3': 'Realize flexible block development'
  },
  'zh-CN': {
    'login.form.title': '登录 梦的天空之城',
    'login.form.phone.errMsg': '手机号不能为空',
    'login.form.code.errMsg': '手机验证码不能为空',
    'login.form.phone.placeholder': '请输入手机号',
    'login.form.code.placeholder': '手机验证码',
    'login.form.next': '下一步',
    'login.form.login': '登录',
    'login.form.register': '注册账号',
    'login.banner.slogan1': '开箱即用的高质量模板',
    'login.banner.subSlogan1': '丰富的的页面模板，覆盖大多数典型业务场景',
    'login.banner.slogan2': '内置了常见问题的解决方案',
    'login.banner.subSlogan2': '国际化，路由配置，状态管理应有尽有',
    'login.banner.slogan3': '接入可视化增强工具AUX',
    'login.banner.subSlogan3': '实现灵活的区块式开发'
  }
};
export default i18n;